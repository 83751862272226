import React from "react"
import { Link, graphql } from "gatsby"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Wrapper from "../components/Wrapper"

const BlogIndex = ({ data }) => {
  const posts = data.allMdx.edges

  return (
    <Layout>
      <SEO title="All posts" />
      <Wrapper margin="2em" padding="2em">
        <div
          css={css`
            label: post-list;
            max-width: 800px;
            margin: 0 auto;
          `}
        >
          <h1
            css={css`
              padding-left: 0;
              margin-bottom: 2em;
              border-bottom: 1px dotted #fdfdfd;
              font-size: 2em;
              color: #fdfdfd;
            `}
          >
            Blog
          </h1>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div
                key={node.fields.slug}
                css={css`
                  label: blog-post;
                  display: flex;
                  height: 200px;
                  flex-direction: column;
                  background: #18272e;
                  padding: 1em;
                  border-radius: 1em;
                  box-shadow: 0.5em 0.5em 0.3em rgba(0, 0, 0, 0.3),
                    0 0 0.3em rgba(0, 0, 0, 0.3);
                  margin-bottom: 3em;
                  transition: 0.3s ease-out 0.2s;
                  position: relative;
                  opacity: 0.75;
                  &:hover {
                    box-shadow: 0.5em 1.5em 0.5em rgba(0, 0, 0, 0.3),
                      0 0 0.3em rgba(0, 0, 0, 0.3);
                    transform: translateY(-1em);
                    transition: ease-in 0.2s;
                    opacity: 1;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    label: blog-post-body;
                  `}
                >
                  <div>
                    <h3
                      css={css`
                        padding: 0;
                        font-size: 1.9em;
                        letter-spacing: 0.1em;
                        margin: 0;
                      `}
                    >
                      <Link
                        css={css`
                          text-decoration: none;
                          box-shadow: none;
                        `}
                        to={"/blog" + node.fields.slug}
                      >
                        {title}
                      </Link>
                    </h3>

                    <small>{node.frontmatter.date}</small>
                    <Link
                      css={css`
                        text-decoration: none;
                        box-shadow: none;
                        display: block;
                      `}
                      to={"/blog" + node.fields.slug}
                    >
                      <p
                        css={css`
                          opacity: 0.8;
                          line-height: 1.2em;
                          max-height: 3.6em;
                          overflow: hidden;
                          /* for set '...' in absolute position */
                          position: relative;
                          text-align: justify;
                          
                        `}
                      >
                        {node.frontmatter.summary}
                      </p>
                    </Link>
                  </div>
                  <div
                    css={css`
                      width: 500px;
                      margin-left: 2em;
                      overflow: hidden;
                      label: post-image-wrapper;
                    `}
                  >
                    <Link to={"/blog" + node.fields.slug}>
                      <Img
                        css={css`
                          height: 100%;
                        `}
                        fluid={
                          node.frontmatter.hero_image.childImageSharp.fluid
                        }
                        alt={node.frontmatter.title}
                      />
                    </Link>
                  </div>
                </div>
                <Link
                  css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                  `}
                  to={"/blog" + node.fields.slug}
                ></Link>
              </div>
            )
          })}
        </div>
      </Wrapper>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: {published: {eq: true}}, fileAbsolutePath: {regex: "/content\\/blog/"}}
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            summary
            hero_image {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_tracedSVG
                  #...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
